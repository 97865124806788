// 会社概要 Googlemap
if($("body").hasClass("map")){
  google.maps.event.addDomListener(window, 'load', init);
}
function init() {
  // map表示
  var latlng = new google.maps.LatLng(35.666439,139.702272);
  var myOptions = {
      zoom: 18,
      center: latlng,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
  var map = new google.maps.Map(document.getElementById('map_canvas'), myOptions);

  // map style設定
  var styleOptions = [
      {
        "stylers": [
          {
            "hue": "#000000"
          },
          {
            "saturation": -100
          }
        ],
        "elementType": "all",
        "featureType": "all"
      }
    ];
  var styledMapOptions = { name: 'DIGITALRISE' }
  var StyledMapType = new google.maps.StyledMapType( styleOptions, styledMapOptions );
  map.mapTypes.set( 'map_canvas', StyledMapType );
  map.setMapTypeId( 'map_canvas' );

  // マーカー設定
  var markerOptions = {
      position: latlng,
      map: map,
      title: 'DIGITALRISE'
  };
  var marker = new google.maps.Marker(markerOptions);

  // infobox用のDOMを生成
  var infoboxContent = '<div class="map__info">DIGITALRISE</div>';
  var infoboxOptions = {
		content: infoboxContent, // 生成したDOMを割り当てる
		pixelOffset: new google.maps.Size(-58, -54), // オフセット値
		alignBottom: true, // 位置の基準
    position: latlng, // 位置の座標
    closeBoxURL: "",
		boxClass: "map__box", // 生成したDOMをラップするdivのclass名
  };
	// infoboxを生成して表示
	var infobox = new InfoBox(infoboxOptions);
  // infoboxを表示
	infobox.open(map, marker);
	// マーカーがクリックされた時にinfoboxを表示
	marker.addListener('click', function() {
		infobox.open(map, marker);
  });

  // var infoWindow = new google.maps.InfoWindow( { // 吹き出しの追加
  //     content: '<div class="map__info">DIGITALRISE</div>' // 吹き出しに表示する内容
  // });
  // infoWindow.open( map, marker ) ;
}
//35.666439, 139.702272