$(window).scroll(function () {
	if($(".header").hasClass("header--transparent")){
		return false;
	}
	var scroll   = $(this).scrollTop();
	var header   = $(".header").height();
	if(scroll >=  header) {
		$('.header').addClass('header--scroll');
	}else{
		$('.header').removeClass('header--scroll');
	}
});