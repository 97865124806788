/**
 * slick slider
 */
var sliderContents = [
	["ランドセルは海を超えて", "2004 -", "株式会社クラレ", "/works/works01.html"],
	["第18回ベストフォーマリスト賞","2000 -","日本フォーマル協会", "/works/works02.html"],
	["Iruma-cc WEBサイト","2017.6","入間カントリー倶楽部", "/works/works03.html"]
]
// TOP PC slider
$('.slider-pc').slick(
	{
		autoplay: false,
		autoplaySpeed: 4000,
		speed: 1000,
		infinite: true,
		pauseOnHover: true,
		pauseOnFocus: true,
		dots: true,
		arrows: true,
		centerMode: true,
		centerPadding: '10%',
		prevArrow: '',
		nextArrow: '<img src="/images/btn_slider_arrow.png">',
		slide: 'div.slider__container',
	}
);
// TOP SP slider
$('.slider-sp').slick(
	{
		autoplay: true,
		autoplaySpeed: 4000,
		speed: 1000,
		infinite: true,
		pauseOnHover: true,
		pauseOnFocus: true,
		dots: false,
		arrows: true,
		centerMode: true,
		centerPadding: '10%',
		prevArrow: '',
		nextArrow: '<img src="/images/btn_slider_arrow_sp.png">',
		slide: 'div.slider__container',
	}
);

// TOP PC slider 右サイドhover 拡大
$('.slider-pc .slider__container').hover(
	function() {
		if($(this).hasClass('slick-active')){
			return false;
		}
		var marginPx = $('.slick-slider').width() * 0.08;
		$('div.slick-active').stop().animate({ 
			marginLeft: '-' + marginPx,
		}, 300, function(){
			// $(".div.slick-active").queue([]);
			// $(".div.slick-active").stop();
		});
	},
	function() {
		$('div.slick-active').stop().animate({ 
			marginLeft: "0",
		}, 300, function(){
			// $(".div.slick-active").queue([]);
			// $(".div.slick-active").stop();
		});
	}
);

// TOP PC slider 右サイドclick スライド設定
$('.slider__container').click(function(){
	if($(this).hasClass('slick-active')){
		if($(this).find('a').length){
			var link = $(this).find('a').attr("href");
			window.location(link);
		}
		return false;
	}
	$('.slider__container').css("margin-left", "0");
	$('.slider-pc').slick('slickNext');
	$('.slider-sp').slick('slickNext');
});

$('.slider-pc').on('beforeChange', function(event, slick, currentSlide, nextSlide){
	$('.slider-pc .slider__container').css("margin-left", "0");
	$(".slider-pc .slider__contents p").fadeOut();
});
$('.slider-pc').on('afterChange', function(event, slick, currentSlide, nextSlide){
	$('.slider__container').css("margin-left", "0");
	var i = $('.slider-pc').slick('slickCurrentSlide');
	$(".slider-pc .slider__contents .slider__title").text(sliderContents[i][0]);
	$(".slider-pc .slider__contents .slider__date").text(sliderContents[i][1]);
	$(".slider-pc .slider__contents .slider__client").text(sliderContents[i][2]);
	$(".slider-pc .slider__contents .slider__link").attr("href",sliderContents[i][3]);
	$(".slider-pc .slider__contents p").fadeIn();
});

$('.slider-sp').on('beforeChange', function(event, slick, currentSlide, nextSlide){
	$(".slider-sp .slider__contents p").fadeOut();
});
$('.slider-sp').on('afterChange', function(event, slick, currentSlide, nextSlide){
	var i = $('.slider-sp').slick('slickCurrentSlide');
	$(".slider-sp .slider__contents .slider__title").text(sliderContents[i][0]);
	$(".slider-sp .slider__contents .slider__date").text(sliderContents[i][1]);
	$(".slider-sp .slider__contents .slider__client").text(sliderContents[i][2]);
	$(".slider-sp .slider__contents .slider__link").attr("href",sliderContents[i][3]);
	$(".slider-sp .slider__contents p").fadeIn();
});


var sidePadding = calcSliderPadding();
// works PC slider 
$('.slider-works-pc').slick(
	{
		autoplay: false,
		autoplaySpeed: 4000,
		speed: 1000,
		infinite: true,
		dots: false,
		arrows: true,
		centerMode: true,
		centerPadding: sidePadding,
		prevArrow: '<img src="/images/btn_slider_prev.png">',
		nextArrow: '<img src="/images/btn_slider_next.png">',

	}
);

// works SP slider 
$('.slider-works-sp').slick(
	{
		autoplay: true,
		autoplaySpeed: 4000,
		speed: 1000,
		infinite: true,
		dots: false,
		arrows: true,
		centerMode: true,
		centerPadding: '10%',
		prevArrow: '<img src="/images/btn_slider_prev.png">',
		nextArrow: '<img src="/images/btn_slider_next.png">',

	}
);

$(window).resize(function() {
	var sidePadding = calcSliderPadding();
	$('.slider-works-pc').slick('slickSetOption', 'centerPadding', sidePadding, true);
});

function calcSliderPadding(){
	var windowSize = $(window).width();
	var sidePadding = "10%";
	if(windowSize >= 1374){
		sidePadding = ( windowSize - 1100 ) / 2 + "px";
	}
	return sidePadding;
}